import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/news",
    name: "新闻",
    component: () => import("../views/Layout/Layout.vue"),
  },
  {
    path: "/hd/news",
    name: "活动",
    component: () => import("../views/Layout/Layout.vue"),
  },
  {
    path: "/ct",
    name: "创投",
    component: () => import("../views/Layout/Layout.vue"),
  },
  {
    path: "/volunteer",
    name: "志愿者招募",
    component: () => import("../views/Layout/Layout.vue"),
  },
  // {
  //   path: "/jb",
  //   name: "嘉宾",
  //   component: () => import("../views/Layout/Layout.vue"),
  // },
  {
    path: "/zy/mvdetail",
    name: "电影详情",
    component: () => import("../views/Layout/Layout.vue"),
  },
  {
    path: "/zy/benjie",
    name: "本届电影展映",
    component: () => import("@/views/FilmShow.vue"),
  },
  {
    path: "/zy/wangjie",
    name: "往届电影展映",
    component: () => import("@/views/FilmShow.vue"),
  },
  // {
  //   path: "/zy/show",
  //   name: "电影展映",
  //   component: () => import("@/views/FilmShow.vue"),
  // },
  {
    path: "/hd/index",
    name: "浙江大学电影周",
    component: () => import("@/views/Gallery.vue"),
  },
  {
    path: "/hd/hgs",
    name: "电影好故事",
    component: () => import("@/views/Gallery.vue"),
  },
  {
    path: "/hd/px",
    name: "十大浙江出品影片评选",
    component: () => import("@/views/Gallery.vue"),
  },
  {
    path: "/zjr/index",
    name: "电影·浙江日",
    component: () => import("@/views/Gallery.vue"),
  },
  {
    path: "/dh/index",
    name: "对话",
    component: () => import("@/views/Gallery.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
];

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
