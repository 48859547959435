<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        screenWidth: document.documentElement.clientWidth,
      };
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.documentElement.clientWidth;
        })();
      };
      if (this.screenWidth > 900) {
        const url = window.location.href;
        const hash = window.location.hash;
        if (url.indexOf("inside/#") == -1) {
          window.location.href = "https://www.dyz.zjfilm.cn/" + hash;
        } else {
          window.location.href = "https://www.dyz.zjfilm.cn/inside/" + hash;
        }
      }
    },
  };
</script>
<style lang="postcss">
  @import "./assets/css/reset.css";

  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #fff;
    overflow-x: hidden;
  }
  // 自定义滚动条样式
  ::-webkit-scrollbar-track-piece {
    width: 0px;
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    height: 50px;
    cursor: pointer;
    background-color: #e6e6e6;
  }
  *,
  :after,
  :before {
    box-sizing: border-box;
  }
</style>
